@use '~/styles/modules' as *;

.banner {
  margin-bottom: 16px;  
  text-shadow: 0 1px 12px rgb(0 0 0 / 35%);

  @include small-screens() {
    margin-bottom: 24px;  
  }
  @include medium-screens() {
    margin-bottom: 32px;  
  }

  h1 {
    z-index: 2;
    font: $h1;
    text-align: center;

    @include medium-screens() {
      font: $h1_banner;
    }
  }
}
.innerContainer {
  position: absolute;
  @include flexContainer(column, center, center);
  z-index: 2;
}

.imageContainer {
  opacity: 0.6;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.flex {
  @include flexContainer();
}

.BoldText {
  font-weight: 800;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 125%;
  text-align: center;
  padding: 0 24px;

  @include small-screens() {
    font-size: 36px;
  }
}

.logo {
  width: 150px;
  pointer-events: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  @include small-screens() {
    font-size: 36px;
    width: 200px;
  }
}

.author {
  font-weight: bold;
  letter-spacing: -0.01em;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  @include small-screens() {
    font-size: 24px;
    line-height: 29px;
  }
}
